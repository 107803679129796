body {
  font-size: 20px;
  font-family: -apple-system, sans-serif;
  background-color: #fafafc;
  /* background-color: blue; */
}

button {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 1rem;
  position: absolute;
  z-index: 100;
  bottom: 10%;
  left: 10%;
  text-transform: uppercase;
}

button:focus {
  outline: none;
  box-shadow: none;
}

h1 {
  font-weight: 500;
  line-height: 1;
  font-size: 36px;
}

.grid {
  display: grid;
  grid-template-columns: auto(1fr, 5);
}

.image {
  position: absolute;
  cursor: pointer;
  /* filter: drop-shadow(5px 5px 2.5px rgba(0, 0, 0, 0.05)); */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  opacity: 0.95;
  border-radius: 1px;
  /* max-height: 17vw; */
}
.link-image {
  position: relative;

  /* filter: drop-shadow(5px 5px 2.5px rgba(0, 0, 0, 0.05)); */
  opacity: 0.95;
  border-radius: 1px;
  /* max-height: 17vw; */
}

.content {
  top: 200px;
  position: absolute;
}

.header {
  font-size: 16px;
  font-weight: 600;
}

.header-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 1rem 0;
}
.nav-wrapper {
  display: grid;
  width: 25vw;
  grid-template-columns: 1fr 1fr 1fr 1fr 3fr;
  border: 1px solid #edeef1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}

.subtitle {
  grid-column: 2;
  font-size: 16px;
  font-weight: 400;
}

.tool {
  width: 2.25rem;
  padding: 0.25rem;
  border-right: 1px solid #e9eaed;
}

.tool * {
  width: 70%;
}

.folder {
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 16px;
}
.link {
  font-size: 14px;
  color: gray;
}

.text-block {
  position: absolute;
  background-color: white;
  padding: 0rem;
  color: rgb(51, 51, 51);
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  /* filter: drop-shadow(5px 5px 2.5px rgba(0, 0, 0, 0.05)); */
  max-height: 17vw;
  overflow-y: auto;
  font-size: 16px;
}

.top-container {
  z-index: 1000;
  width: 100vw;
  /* position: relative; */
  position: absolute;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform;
}
